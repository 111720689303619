<template>
  <div class="auth-page">
    <div class="container page">
      <AppointmentsList />
    </div>
  </div>
</template>

<script>
import AppointmentsList from "@/components/operator/AppointmentsList";

export default {
  name: "Dashboard",
  components: {
    AppointmentsList
  }
};
</script>
