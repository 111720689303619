<template>
  <v-card class="mt-9 px-8 py-12">
    <v-form>
      <v-row>
        <v-col cols="12" sm="4">
          <v-layout wrap>
            <v-menu
              v-model="fromDateMenu"
              :close-on-content-click="true"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Appuntamenti previsti per il giorno"
                  filled
                  readonly
                  clearable
                  :value="fromDateDisp"
                  v-on="on"
                  @click:clear="
                    searchDate = null;
                    fetchAppointments();
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                locale="it"
                v-model="searchDate"
                no-title
                @input="fetchAppointments()"
              ></v-date-picker>
            </v-menu>
          </v-layout>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="searchStatus"
            :items="appointmentStatuses"
            filled
            item-text="text"
            item-value="value"
            label="Stato appuntamento"
            :return-object="true"
            @change="fetchAppointments()"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            id="searchFrontOfficeId"
            v-model="searchFrontOffice"
            filled
            :items="frontOffices"
            item-text="front_office_name"
            item-value="front_office_id"
            label="Sportello"
            :return-object="true"
            @change="fetchAppointments()"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-form>

    <v-data-table
      data-app
      id="app-table"
      :headers="headers"
      :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
      :items="appointments"
      :loading="loading"
      :options.sync="options"
      :server-items-length="appointmentsCount"
      locale="it"
      @update:page="fetchAppointments()"
      @update:sortBy="fetchAppointments()"
      @update:options="fetchAppointments()"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-8">
          <v-row align="center">
            <v-col>
              <v-toolbar-title>Appuntamenti</v-toolbar-title>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-md-right">
              <!-- <AppointmentEditor v-on:postappok="fetchAppointments()" /> -->
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item.full_name`]="{ item }">
        <span class="fontSemibold"
          >{{ item.user.name }} {{ item.user.surname }}</span
        >
      </template>
      <template v-slot:[`item.begin_date`]="{ item }">
        {{ formatDate(item.begin_date) }}
      </template>
      <template v-slot:[`item.end_date`]="{ item }">
        {{ formatDate(item.end_date) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-badge :color="badgeColor(item.status)" inline left dot>
          {{ item.status | translateStatus }}
        </v-badge>
      </template>
      <template v-slot:[`item.front_office_name`]="{ item }">
        {{ frontOfficeName(item.front_office_name) }}
      </template>
      <template v-slot:[`item.detail`]="{ item }">
        <v-btn
          outlined
          class="btnSecondary"
          small
          :to="{
            name: 'appointment-detail',
            params: { slug: item.appointment_id }
          }"
        >
          VEDI
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import {
  APPOINTMENTS_FETCH,
  APPOINTMENT_SEARCH_PARAMS_UPDATE
} from "@/store/actions.type";

export default {
  name: "AppointmentsList",
  data() {
    return {
      loading: false,
      fromDateMenu: false,
      frontOffices: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["begin_date"],
        sortDesc: [false]
      },
      headers: [
        {
          value: "full_name",
          text: "Utente",
          sortable: false
        },
        { value: "begin_date", text: "Inizio" },
        { value: "end_date", text: "Fine", sortable: false },
        { value: "status", text: "Stato", sortable: false },
        { value: "front_office_name", text: "Sportello", sortable: false },
        { value: "detail", text: "Dettaglio", sortable: false }
      ],
      searchDate: null,
      searchFrontOffice: {},
      searchStatus: {}
    };
  },
  computed: {
    ...mapGetters([
      "appointmentSearchParams",
      "appointmentStatuses",
      "appointments",
      "appointmentsCount",
      "currentFrontOffices",
      "currentOperator"
    ]),
    fromDateDisp() {
      return this.searchDate != null
        ? moment.utc(this.searchDate).format("DD/MM/YYYY")
        : "";
    }
  },
  methods: {
    fetchAppointments() {
      this.loading = true;
      this.$store
        .dispatch(APPOINTMENTS_FETCH, this.listConfig())
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    formatDate(inputDate) {
      return moment.utc(inputDate).format("DD/MM/YYYY HH:mm");
    },
    frontOfficeName(foName) {
      if (foName == null || foName == "") return "N.D.";
      return foName;
    },
    getFrontOfficeIds() {
      if (this.searchFrontOffice.front_office_id != null) {
        return { eq: this.searchFrontOffice.front_office_id };
      }
      let foIds = ""; //"AAA,BBB,CCC"
      const foList = this.frontOffices;
      for (const idx in foList) {
        if (foList[idx].front_office_id == null) continue;
        if (foIds != "") foIds += ",";
        foIds += foList[idx].front_office_id;
      }
      return foIds != "" ? { in: foIds } : null;
    },
    listConfig() {
      const cfg = {
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        limit: this.options.itemsPerPage,
        filter: {}
      };
      const listFoIds = this.getFrontOfficeIds();
      if (listFoIds != null) cfg.filter.front_office_id = listFoIds;

      if (this.options.sortBy[0] != undefined) {
        cfg.sort =
          (!this.options.sortDesc[0] ? "+" : "-") + this.options.sortBy[0];
      }
      if (this.searchDate != null) {
        cfg.filter.begin_date = { gte: this.searchDate + "T00:00:00.000+0000" };
        cfg.filter.end_date = { lte: this.searchDate + "T23:59:59.000+0000" };
      } else {
        if ("begin_date" in cfg.filter) delete cfg.filter.begin_date;
        if ("end_date" in cfg.filter) delete cfg.filter.end_date;
      }
      if (this.searchStatus.value != null) {
        cfg.filter.status = { eq: this.searchStatus.value };
      } else {
        if ("status" in cfg.filter) delete cfg.filter.status;
      }

      this.$store.dispatch(APPOINTMENT_SEARCH_PARAMS_UPDATE, {
        date: this.searchDate,
        frontOffice: this.searchFrontOffice,
        status: this.searchStatus
      });

      return cfg;
    },
    badgeColor(color) {
      let colorStatus = "";
      switch (color) {
        case "open":
          colorStatus = "success";
          break;
        case "confirmed":
          colorStatus = "success";
          break;
        case "closed":
          colorStatus = "error";
          break;
        case "deleted":
          colorStatus = "error";
          break;
      }
      return colorStatus;
    }
  },
  created() {
    this.frontOffices = [this.appointmentSearchParams.frontOffice].concat(
      this.currentFrontOffices
    );
    this.searchDate = this.appointmentSearchParams.date;
    this.searchFrontOffice = this.appointmentSearchParams.frontOffice;
    this.searchStatus = this.appointmentSearchParams.status;
    this.fetchAppointments();
    this.badgeColor();
  }
};
</script>
